import React, { useEffect, useState } from "react";
import MyList from "../component/MyList";
import MyCombobox from "../component/MyCombobox";
import SearchAyat from "../component/SearchAyat";
import GoBack from "../component/GoBack";
import { request_search } from "../common/web";
import "../styles/mystyle.css";

function SearchIki() {
  const [srch1, srch1_set] = useState("1");
  const [srch2, srch2_set] = useState("1");
  const [word1, word1_set] = useState("رحمة");
  const [word2, word2_set] = useState("حكمة");
  //const [task , task_set ] = useState(0);

  const [klmt, klmt_set] = useState({});
  const [ayat, ayat_set] = useState({});

  const head = ["سورة", "آية", "نص"];

  const [huruf, setHuruf] = useState({});
  const [aalam, setAalam] = useState({});
  const [jthur, setJthur] = useState({});
  //const [hosna, setHosna] = useState({});

  const handleElement = (event) => {
    word1_set(event.target.value);
  };

  async function initiate() {
    var data, error;
    [data, error] = await request_search({ code: 201 });
    setHuruf(data);
    if (error !== "") {
      alert(error);
      return;
    }
    [data, error] = await request_search({ code: 202 });
    setAalam(data);
    if (error !== "") {
      alert(error);
      return;
    }
    [data, error] = await request_search({ code: 203 });
    setJthur(data);
    if (error !== "") {
      alert(error);
      return;
    }
    /*
    [data, error] = await request_search({ code: 204 });
    setHosna(data);
    if (error !== "") {
      alert(error);
      return;
    }
    */
  }

  useEffect(() => {
    initiate();
  }, []);


  function SearchText({ selectedSearch, onSearchChange }) {
    return (
      <div style={{ textAlign: 'left' }} >
        <div> {" البحث عن المفردة باعتبارها "} </div>
        <div> {" كلمة مستقلة "} </div>
        <div> {" جزء من كلمة "} </div>
        <div> {" جذرا "} </div>
      </div>
    );
  }

  function SearchOption({ selected, onChange }) {
    const handleOptionChange = (event) => {
      onChange(event.target.value);
    };
    return (
      <div>
        <div> <input type="radio" value="1" checked={selected === "1"} onChange={handleOptionChange} /> </div>
        <div> <input type="radio" value="2" checked={selected === "2"} onChange={handleOptionChange} /> </div>
        <div> <input type="radio" value="3" checked={selected === "3"} onChange={handleOptionChange} /> </div>
      </div>
    );
  }

  const handleWord1Change = (event) => {
    event.preventDefault();
    word1_set(event.target.value);
  };
  const handleWord2Change = (event) => {
    event.preventDefault();
    word2_set(event.target.value);
  };

  function SearchButton({ task, text }) {
    async function searchClick() {
      var ayat1, klmt1, error;
      [ayat1, error] = await request_search({ code: 501, task: task, word1: word1, srch1: srch1, word2: word2, srch2: srch2 });
      if (error !== "") { alert(error); return; }
      //console.log(task, word1, word2, srch1, srch2,);
      //console.log(ayat1);
      [klmt1, error] = await request_search({ code:601 , task:task , word1:word1 , srch1:srch1 , word2:word2 , srch2:srch1 });
      if (error !== "") { alert(error); return; }
      ayat_set(ayat1);
      klmt_set(klmt1);
    }
    return (<button onClick={searchClick} style={{ width: 150 }} > {text} </button>);
  }

  return (
    <div className="col" style={{ fontSize: 14 }}>
      <label style={{ fontSize: "20px" }}> بــحــث 2 </label>
      <hr />
      <div className="row">
        <GoBack />
        <MyCombobox list={huruf} handleChange={handleElement} title="حروف" />
        <MyCombobox list={aalam} handleChange={handleElement} title="أعلام" />
        <MyCombobox list={jthur} handleChange={handleElement} title="جذور" />
        {/* <MyCombobox list={hosna} handleChange={handleElement} title="حسنى" /> */}
      </div>
      <hr />
      <div className="row">
        <SearchText />
        <div>
          <input
            type="text"
            placeholder=" ؟ ؟ ؟ "
            value={word1}
            onChange={handleWord1Change}
            style={{ width: 50 }}
          />
          <SearchOption selected={srch1} onChange={srch1_set} />
        </div>
        <div>
          <input
            type="text"
            placeholder=" ؟ ؟ ؟ "
            value={word2}
            onChange={handleWord2Change}
            style={{ width: 50 }}
          />
          <SearchOption selected={srch2} onChange={srch2_set} />
        </div>
      </div>
      <hr />
      <SearchButton task={1} text={" بـــحـــث عن المفردة الأولى  "} />
      <SearchButton task={2} text={" بـــحـــث عن المفردة الثانية "} />
      <SearchButton task={3} text={" بـــحـــث عن الأولى أو الثانية "} />
      <SearchButton task={4} text={" بـــحـــث  عن الأولى و الثانية "} />
      <br />
      <label> عدد الكلمات: {klmt.length} </label>
      <MyList data={klmt} />
      <br />
      <label> عدد الآيات {ayat.length} </label>
      <SearchAyat head={head} ayat={ayat} kalimat={klmt} />
    </div>
  );
}
export default SearchIki;
