import React, { useEffect, useState } from "react";
import SearchSelector from "../component/SearchSelector";
import MyList from "../component/MyList";
import MyCombobox from "../component/MyCombobox";
import SearchAyat from "../component/SearchAyat";
import GoBack from "../component/GoBack";
import { request_search } from "../common/web";
import "../styles/mystyle.css";

function Search() {
  const [search, setSearch] = useState("1");
  const [word, setWord] = useState("رحمة");
  const [kalimat, setKalimat] = useState({});
  const [ayat, ayat_set] = useState({});

  const head = ["سورة", "آية", "نص"];

  const [huruf, setHuruf] = useState({});
  const [aalam, setAalam] = useState({});
  const [jthur, setJthur] = useState({});
  //const [hosna, setHosna] = useState({});

  const handleElement = (event) => {
    setWord(event.target.value);
  };

  async function initiate() {
    var data, error;
    [data, error] = await request_search({ code: 201 });
    setHuruf(data);
    if (error !== "") {
      alert(error);
      return;
    }
    [data, error] = await request_search({ code: 202 });
    setAalam(data);
    if (error !== "") {
      alert(error);
      return;
    }
    [data, error] = await request_search({ code: 203 });
    setJthur(data);
    if (error !== "") {
      alert(error);
      return;
    }
    /*
    [data, error] = await request_search({ code: 204 });
    setHosna(data);
    if (error !== "") {
      alert(error);
      return;
    }
    */
  }

  useEffect(() => {
    initiate();
  }, []);

  const handleSearchChange = (choice) => {
    setSearch(choice);
  };
  const handleWordChange = (event) => {
    setWord(event.target.value);
  };

  async function searchClick() {
    var ayat1, klmt1, error;
    switch (search) {
      case "1":
        [ayat1, error] = await request_search({ code: 301, word: word });
        [klmt1, error] = await request_search({ code: 401, word: word });
        break;
      case "2":
        [ayat1, error] = await request_search({ code: 302, word: word });
        [klmt1, error] = await request_search({ code: 402, word: word });
        break;
      case "3":
        [ayat1, error] = await request_search({ code: 303, word: word });
        [klmt1, error] = await request_search({ code: 403, word: word });
        break;
      default:
    }
    if (error !== "") {
      alert(error);
      return;
    }
    ayat_set(ayat1);
    setKalimat(klmt1);
  }

  return (
    <div className="col" style={{ fontSize: 14 }}>
      <label style={{ fontSize: "20px" }}> بــحــث </label>
      <hr />
      <div className="row">
        <GoBack />
        <MyCombobox list={huruf} handleChange={handleElement} title="حروف" />
        <MyCombobox list={aalam} handleChange={handleElement} title="أعلام" />
        <MyCombobox list={jthur} handleChange={handleElement} title="جذور" />
        {/* <MyCombobox list={hosna} handleChange={handleElement} title="حسنى" /> */}
      </div>
      <hr />
      <div>
        <label> المفردة </label>
        <input
          type="text"
          placeholder=" ؟ ؟ ؟ "
          value={word}
          onChange={handleWordChange}
          style={{ width: 50 }}
        />

        {/* 
        <label> المفردة 2 </label>
        <input type="text" placeholder=" ؟ ؟ ؟ " value={word} onChange={handleWordChange} style={{ width: 50 }} />
        */}
      </div>
      <SearchSelector
        selectedSearch={search}
        onSearchChange={handleSearchChange}
      />
      <button onClick={searchClick}> بـــحـــث </button>
      <br />
      <label> عدد الكلمات: {kalimat.length} </label>
      <MyList data={kalimat} />
      <br />
      <label> عدد الآيات {ayat.length} </label>
      <SearchAyat head={head} ayat={ayat} kalimat={kalimat} />
    </div>
  );
}
export default Search;
