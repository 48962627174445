import React, { useState, useEffect } from 'react'
import { useMyContext } from '../component/MyContext'
import { request_explor } from '../common/web'
import GoBack from '../component/GoBack'
import Sourat from '../common/Sourat'
import Aayat from '../common/Aayat'
import '../styles/mystyle.css'

function ExplorRiwaya() {
  const { sura, sura_set, aaya, aaya_set } = useMyContext();
  const [page, page_set] = useState("");
  const [ayat, ayat_set] = useState([]);

  useEffect(
    () => {
      display_page(ayat);
    }, [sura, ayat]
  );

  useEffect(
    () => {
      request_aaya(sura, aaya);
    }
    , [sura, aaya]
  );

  function display_page(ayat) {
    page_set(<RiwayatPaint data={ayat} />);
  }

  function RiwayatPaint({ data }) {
    return (
      <table>
        <thead>
          <tr><th> روايـــة </th><th> سورة {data.sura}     آية  {data.aaya}</th></tr>
        </thead>
        <tbody>
          <tr><td>حفص  </td><td style={{ fontFamily: "KFGQPC HafsEx1 Uthmanic Script" }}>{data.hafs} </td></tr>
          <tr><td>ورش  </td><td style={{ fontFamily: "KFGQPC WARSH Uthmanic Script" }}>{data.warsh} </td></tr>
          <tr><td>بزّي  </td><td style={{ fontFamily: "KFGQPC BAZZI Uthmanic Script" }}>{data.bazzi} </td></tr>
          <tr><td>دوري </td><td style={{ fontFamily: "KFGQPC DOORI Uthmanic Script" }}>{data.doori} </td></tr>
          <tr><td>قالون</td><td style={{ fontFamily: "KFGQPC QALOON Uthmanic Script" }}>{data.qaloon} </td></tr>
          <tr><td>قنبل </td><td style={{ fontFamily: "KFGQPC QUMBUL Uthmanic Script" }}>{data.qumbul} </td></tr>
          <tr><td>شعبة </td><td style={{ fontFamily: "KFGQPC SHOUBA Uthmanic Script" }}>{data.shouba} </td></tr>
          <tr><td>سوسي </td><td style={{ fontFamily: "KFGQPC SOOSI Uthmanic Script" }}>{data.soosi} </td></tr>
        </tbody >
      </table >
    );
  };

  async function request_aaya(sura, aaya) {
    var data, error;
    const query = { "code": 401, "sura": sura, "aaya": aaya };
    [data, error] = await request_explor(query);
    if (error !== "") {
      alert(error);
      return;
    }
    ayat_set(data);
  }

  function NextAaya() {
    async function handleClick(e) {
      var data, error;
      const query = { "code": 402, "sura": sura, "aaya": aaya };
      [data, error] = await request_explor(query);
      if (error !== "") {
        alert(error);
        return;
      }
      sura_set(data.sura);
      aaya_set(data.aaya);
    }
    return (
      <div>
        <button onClick={handleClick}> {">>"} </button>
      </div>
    )
  }

  function PrevAaya() {
    async function handleClick(e) {
      var data, error;
      const query = { "code": 403, "sura": sura, "aaya": aaya };
      [data, error] = await request_explor(query);
      if (error !== "") {
        alert(error);
        return;
      }
      sura_set(data.sura);
      aaya_set(data.aaya);
    }
    return (
      <div>
        <button onClick={handleClick}>{"<<"}</button>
      </div>
    )
  }

  function DisplayPage() {
    return (
      <div > {page} </div>
    )
  }

  const [surat_visible, surat_visible_set] = useState(false);
  const surat_show = () => surat_visible_set(true);
  //const surat_hide = () => surat_visible_set(false);
  const surat_hide = () => {
    surat_visible_set(false);
    aaya_set(1);
  }
  function GetSurat() {
    return (<div> <button onClick={surat_show}> {sura} </button> </div>);
  }

  const [aaya_visible, aaya_visible_set] = useState(false);
  const aaya_show = () => aaya_visible_set(true);
  const aaya_hide = () => aaya_visible_set(false);
  function GetAaya() {
    return (<div> <button onClick={aaya_show}> {aaya} </button> </div>);
  }

  return (
    <>
      <Sourat show={surat_visible} onClose={surat_hide}> </Sourat>
      <Aayat  show={aaya_visible}  onClose={aaya_hide}>  </Aayat>
      <div className='col'>
        <div className='row'>
          <label> ( تـــصــفـــح الآيـــات  ) </label>
        </div>
        <div className='row'>
          <GoBack />
          <label>&nbsp;&nbsp;&nbsp; ســــورة </label>
          <GetSurat />
          <label>&nbsp;&nbsp;&nbsp; آيــــة </label>
          <PrevAaya />
          <GetAaya />
          <NextAaya />
        </div>
        <DisplayPage />
        <div className='row'> <PrevAaya /> <NextAaya /> </div>
      </div>
    </>
  )
}

export default ExplorRiwaya