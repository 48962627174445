import { React } from 'react';
//import { useNavigate } from 'react-router-dom';
import { useMyContext } from '../component/MyContext';
//import { request_aaya } from '../common/common';

import '../styles/mystyle.css';

function Sourat({ show, onClose, children }) {
  //const navigate = useNavigate();
  const { sura_set, suar } = useMyContext();

  function SuratPaint({ sura_list }) {
    async function sura_click(sura_no) {
      sura_set(sura_no);
      //aaya_set(1);
      onClose();
    }

    const rows = sura_list.map(item => Object.values(item));
    const boutons = [];
    for (let i = 0; i < sura_list.length; i++) {
      boutons.push(
        <button key={i}
          onClick={() => sura_click(i + 1)}
          className='btn_fahres'>
          {/* {rows[i][0]} - {rows[i][3]} ({rows[i][1]}) */}
          {/* {rows[i][3]} */}
          {rows[i][0]} - {rows[i][3]}
        </button>
      );
    }
    return (<div className='list'> {boutons} </div>);
  }


  function ReturnButton() {
    /*
    function return_click(e) {
      e.preventDefault();
      navigate(-1);
    }
    return (<button onClick={return_click}>رجوع 2</button>)
    */
    return (
      <>
        <button onClick={onClose}> {" ok "} </button>
        {/* <label>...</label> */}
      </>
    )
  }

  if (!show) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>

        <div className='col'>
          <ReturnButton />
          <SuratPaint sura_list={suar} />
        </div>
        {/* <button onClick={onClose} style={styles.closeButton}>X</button> */}

        {children}
      </div>
    </div>
  );
}

const styles = {
  overlay: {
    position: 'fixed',
    top: 0, left: 0, right: 0, bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex', justifyContent: 'center', alignItems: 'center'
  },
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    //width: '300px',
    position: 'relative'
  },
  closeButton: {
    //position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer'
  }
};

export default Sourat;