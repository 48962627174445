import React, { useState, useEffect } from 'react'
import { useMyContext } from '../component/MyContext'
import { RiwayaSelector, FontSelector, get_font } from './suite/RiwayaFont'
import { request_explor } from '../common/web'
import GoBack from '../component/GoBack'
import Safahat from '../common/Safahat'
import '../styles/mystyle.css'
import TableFromJasonFor from '../component/TableFromJasonFor'
import { save_cookie } from '../common/cookies'

function ExplorSafha() {
  const { rwya, rwya_set, sfha, sfha_set } = useMyContext();
  const [page, page_set] = useState("");
  const [ayat, ayat_set] = useState([]);
  const [font, font_set] = useState(rwya);

  useEffect(
    () => {
      display_page(ayat, font);
    }
    , [ayat, font]
  );

  useEffect(
    () => {
      save_cookie("rwya", rwya);
      save_cookie("sfha", sfha);
      request_safha(rwya, sfha);
    }
    , [rwya, sfha]
  );


  function display_page(ayat, font) {
    const head = ['سورة', 'آية', 'نص الآيــة'];
    page_set(<TableFromJasonFor head={head} data={ayat} />)
  }

  async function request_safha(rwya, sfha) {
    var data, error
    const query = { "code": 201, "rwya": Number(rwya), "sfha": Number(sfha) };
    [data, error] = await request_explor(query)
    if (error !== "") {
      alert(error);
      return;
    }
    //console.log(data);
    ayat_set(data);
  }

  function NextPage() {
    async function handleClick(e) {
      var data, error
      const query = { "code": 202, "sfha": sfha };
      [data, error] = await request_explor(query);
      if (error !== "") {
        alert(error);
        return;
      }
      sfha_set(data.sfha);
    }
    return (
      <div>
        <button onClick={handleClick}> {">>"} </button>
      </div>
    )
  }

  function PrevPage() {
    async function handleClick(e) {
      var data, error
      const query = { "code": 203, "sfha": sfha };
      [data, error] = await request_explor(query);
      if (error !== "") {
        alert(error);
        return;
      }
      sfha_set(data.sfha);
    }
    return (
      <div>
        <button onClick={handleClick}>{"<<"}</button>
      </div>
    )
  }

  function DisplayPage() {
    return (
      <div style={{ fontFamily: get_font(font) }}>
        {page}
      </div>
    )
  }

  const [safha_visible, safha_visible_set] = useState(false);
  const safha_show = () => safha_visible_set(true);
  const safha_hide = () => safha_visible_set(false);
  function GetSafha() {
    return (<div> <button onClick={safha_show}> {sfha} </button> </div>);
  }

  return (
    <>
      <Safahat show={safha_visible} onClose={safha_hide}> </Safahat>
      <div className='col'>
        <label> ( تـــصــفـــح الصفحات) </label>
        <div className='row'>
          <GoBack />
          <label>&nbsp;&nbsp;&nbsp; الصـــفحة </label>
          <PrevPage />
          <GetSafha />
          <NextPage />&nbsp;&nbsp;&nbsp;
          <RiwayaSelector selectedRiwaya={rwya} setSelectedRiwaya={rwya_set} setSelectedFont={font_set} />
          <FontSelector selectedFont={font} setSelectedFont={font_set} />
        </div>
        <hr />
        <DisplayPage />
        <div className='row'>
          <PrevPage />
          <NextPage />
        </div>
      </div>
    </>
  )
}

export default ExplorSafha;