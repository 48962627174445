
async function request_script(query, script) {
  const target = "https://rct.tahaleelweb.org/src/php/" + script //
  try {
    var error = "";
    //console.log(query);  ********
    const response = await
      fetch(
        target,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', },
          body: JSON.stringify(query),
        }
      );
      //console.log("response.text",response.text);
    if (response.ok) {
      const data = await response.json();
      return [data, ""];
    } else {
      error = 'Error during query:' + response.statusText
      return [{}, error]
    }
  } catch (err) {
    error = `Unexpected error:${err}`;
    console.error(error);
    return [{}, error]
  }
}

export async function request_explor(query) {
  console.log(query);
  const [data, error] = await request_script(query, "get_explor.php");
  //console.log(data);
  return [data, error];
}
export async function request_search(query) {
  console.log(query);
  const [data, error] = await request_script(query, "get_search.php");
  return [data, error];
}
export async function request_study(query) {
  //console.log(query);
  const [data, error] = await request_script(query, "get_study.php");
  return [data, error];
}



