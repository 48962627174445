import React from "react";

function SearchSelector({ selectedSearch, onSearchChange }) {
  const handleOptionChange = (event) => {
    onSearchChange(event.target.value);
  };

  const opt = [
    "البحث عن المفردة باعتبارها كلمة مستقلة",
    "البحث عن المفردة باعتبارها جزء من كلمة",
    "البحث عن المفردة باعتبارها جذرا",
    //'البحث باعتبار المفردة جذرا (مرتّب بالآيات)',
    "البحث باعتبار المفردة جذرا (مرتّب بالكلمات)",
    "البحث عن جذر المفردة",
    "البحث عن مشتقات جذر المفردة",
  ];

  return (
    <div>
      {/* <h2>Choose your option:</h2> */}
      <div>
        {" "}
        <input
          type="radio"
          value="1"
          checked={selectedSearch === "1"}
          onChange={handleOptionChange}
        />{" "}
        {opt[0]}{" "}
      </div>
      <div>
        {" "}
        <input
          type="radio"
          value="2"
          checked={selectedSearch === "2"}
          onChange={handleOptionChange}
        />{" "}
        {opt[1]}{" "}
      </div>
      <div>
        {" "}
        <input
          type="radio"
          value="3"
          checked={selectedSearch === "3"}
          onChange={handleOptionChange}
        />{" "}
        {opt[2]}{" "}
      </div>
      {/*          
         <div> <input type="radio" value="4" checked={selectedSearch === '4'} onChange={handleOptionChange} /> {opt[3]} </div>
         <div> <input type="radio" value="5" checked={selectedSearch === '5'} onChange={handleOptionChange} /> {opt[4]} </div>
         <div> <input type="radio" value="6" checked={selectedSearch === '6'} onChange={handleOptionChange} /> {opt[5]} </div>
          */}
      {/* <p>Selected Option: {selectedSearch}</p> */}
    </div>
  );
}

export default SearchSelector;
